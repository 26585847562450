import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  locations: any;
  connectLocation: any;
  cmsLocations: any;
  cmsLocation: any;
  approvals: any;
  smsRequestList: any;
  phoneNumbersList: any;
  templates: any;
  dashboardData: any;
  locationUserDetails: any;
  availableNumbersList: any;
  smsRequestDetailData: any;
  phoneNumberDetails: any;
  smsTemplateList: any;
  phoneTreeSettingsList: any;
  phoneTreeSettingDetails: any;
  locationHistoryDetails: any;
  preRecordedVoices: any;
  rentManagerProperties: any;
  esignTemplateList: any;
}

const initialState: InitialState = {
  locations: [],
  connectLocation: null,
  cmsLocations: [],
  cmsLocation: null,
  approvals: [],
  smsRequestList: [],
  phoneNumbersList: [],
  templates: [],
  dashboardData: [],
  locationUserDetails: [],
  availableNumbersList: [],
  smsRequestDetailData: null,
  phoneNumberDetails: null,
  smsTemplateList: null,
  phoneTreeSettingsList: [],
  phoneTreeSettingDetails: {},
  locationHistoryDetails: [],
  preRecordedVoices: [],
  rentManagerProperties: [],
  esignTemplateList: null,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocations: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      locations: action.payload,
    }),
    setLocation: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      connectLocation: action?.payload?.location
        ? {
            ...action.payload,
            location: {
              ...action.payload?.location,
              msas: action.payload?.location?.msas?.toSorted((a: any, b: any) => {
                const timeA = new Date(a.location_msa.created_at).getTime();
                const timeB = new Date(b.location_msa.created_at).getTime();
                if (timeA === timeB) {
                  return a.id - b.id;
                }
                return timeA - timeB;
              }),
            },
          }
        : action.payload,
    }),
    setCmsLocations: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      cmsLocations: action.payload,
    }),
    setCmsLocation: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      cmsLocation: action.payload,
    }),
    setApprovals: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      approvals: action.payload,
    }),
    setTemplates: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      templates: action.payload,
    }),
    setDashboardData: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      dashboardData: action.payload,
    }),
    setLocationUserDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      locationUserDetails: action.payload,
    }),
    setSmsRequestList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      smsRequestList: action.payload,
    }),
    setPhoneNumbersList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      phoneNumbersList: action.payload,
    }),
    setAvailableNumnersList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      availableNumbersList: action.payload,
    }),
    setSMSRequestDetailData: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      smsRequestDetailData: action.payload,
    }),
    setPhoneNumberDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      phoneNumberDetails: action.payload,
    }),
    setSMSTemplateList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      smsTemplateList: action.payload,
    }),
    setPhoneTreeSettingsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      phoneTreeSettingsList: action.payload,
    }),
    setPhoneTreeSettingDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      phoneTreeSettingDetails: action.payload,
    }),
    setLocationHistoryDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      locationHistoryDetails: action.payload,
    }),
    setPreRecordedVoices: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      preRecordedVoices: action.payload,
    }),
    setRentManagerProperties: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      rentManagerProperties: action.payload,
    }),
    setESignTemplateList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      esignTemplateList: action.payload,
    }),
  },
});

export const {
  setLocations,
  setLocation,
  setCmsLocations,
  setCmsLocation,
  setApprovals,
  setTemplates,
  setDashboardData,
  setLocationUserDetails,
  setSmsRequestList,
  setPhoneNumbersList,
  setAvailableNumnersList,
  setSMSRequestDetailData,
  setPhoneNumberDetails,
  setSMSTemplateList,
  setPhoneTreeSettingsList,
  setPhoneTreeSettingDetails,
  setLocationHistoryDetails,
  setPreRecordedVoices,
  setRentManagerProperties,
  setESignTemplateList,
} = locationSlice.actions;

export default locationSlice.reducer;
